<template>
    <div class="login-body">
        <div class="container" v-if="isError">
            <img src="../../assets/img/login_error.png" alt="">
            <div class="error_title">跳转登录失败</div>
            <div class="error_info">跳转登录失败，请向系统管理员<a href="http://wpa.qq.com/msgrd?v=3&amp;uin=273129590&amp;site=qq&amp;menu=yes" class="item kefu-item"> 反馈 </a>此问题</div>
            <div class="error_msg">异常信息：{{errorMsg}}</div>
            <div class="login" @click="backLogin()">返回登录页</div>
        </div>
    </div>
</template>

<script>
import {setLocalStore,setStore,getLocalStore} from "@/util/store";
export default {
    name: "login",
    data() {
        return {
            token: '',
            username: '',
            isError:false
        }
    },
    created: function () {
        this.getToken();
    },
    methods: {
        //获取地址栏token
        getToken(){
            this.token = this.$route.query.token;
            this.username = this.$route.query.username;
            this.loginHome(this.username,this.token)
        },
        //返回登录页
        backLogin(){
            this.$router.push({path: '/'})
        },
        //获取ip和地址信息
        getArea(){
            this.axios.request({
                method: "get", 
                url: '/dms/getUserBehaviorParam',
                params: {
                    userId:getLocalStore('userinfo').id,
                    userName:getLocalStore('userinfo').username
                },
            }).then((res) => {
               setLocalStore('areainfo', res.data.data);

                this.$message.success('登录成功！')
                this.$router.push('/home')
            })
        },
        //临时登录界面
        loginHome(username,token) {
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/serviceLogin',
                data: {
                    username: username,
                    token: token,
                    password:''
                },
            }).then((res) => {
                if (res.data.status == "failed") {
                    this.isError = true;
                    this.errorMsg = res.data.msg;
                } else if (res.data.status == "success") {
                    setLocalStore('loginName', res.data.data.user.loginName)
                    setLocalStore('userinfo', res.data.data.user)
                    setLocalStore('token', res.data.data.token)
                    setLocalStore('permission', res.data.data.permission)
                    this.getArea();
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
.login-body {
    position: relative;
    width: 100%;
    height: 100%;

    .container {
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        width: 300px;
        height: auto;
        .error_title{
            width: 100%;
            text-align: center;
            margin-top: 81px;
            font-size: 32px;
            color: #313131;
        }
        .error_info{
            width: 100%;
            text-align: center;
            margin-top: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #646566;
            >a{
                color: #185BCE;
                cursor: pointer;
            }
        }
        .error_msg{
            width: 100%;
            text-align: center;
            margin-top: 8px;
            font-size: 14px;
            font-weight: 400;
            color: #969799;
        }
        .login{
            width: 102px;
            height: 32px;
            border-radius: 2px;
            border: 1px solid #DCDEE0;
            text-align: center;
            line-height: 30px;
            box-sizing: border-box;
            margin: 40px auto 0;
            color: #323233;
            cursor: pointer;
        }
      
        
    }
}
</style>