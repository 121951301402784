<template>
    <!--    顶部导航栏-->
    <el-header height="60px">
        
        <div class="nav">
            <div class="logo">
                <img src="../../../assets/img/website_logo.png">
            </div>
            <ul class="nav-ul">
                <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="handleSelect"
                    background-color="#fff"
                    text-color="#333"
                    active-text-color="#3368B2">
                    <el-menu-item class="nav-li" index="1" >首页</el-menu-item>
                    <el-menu-item class="nav-li" index="2">产品服务</el-menu-item>
                </el-menu>
            </ul>
            <div class="menu">
                <span class="about" @click="toIndex">关于我们</span>
                <span class="login" @click="toLogin">登录</span>
                <span style="margin-right:16px">|</span>
                <span style="cursor:pointer;" @click="toLogin">注册</span>
            </div>
            <div class="menu" style="margin-left:25px">
                <img src="../../../assets/img/phone.png" alt="">
                <span style="float:right;line-height:60px;">400-8778-367</span>
            </div>
        </div>

    </el-header>
</template>

<script>
export default {
    name: "navbar",
    data() {
        return {
            username: null,
            activeIndex: '1'
        }
    },

    mounted() {
    },
    methods: {
        handleSelect(key, keyPath) {
            if(key==1){
                this.$router.push({path: "/homePage"});
            }else if(key==2){
                this.$router.push({path: "/productService"});
            }
        },
        toIndex(){
            window.open("http://www.puduoinfo.com/",'_blank')
        },
        toLogin(){
            var url = 'http://' + window.location.host + '/#/login'
            window.open(url,'_blank')
        }
    }
}
</script>

<style scoped lang="less">
.el-header {
    width: 100%;
    padding: 0;
    display: flex;
    background: #fff;
    line-height: 60px;
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.5);
    z-index: 9;
    .logo {
        width: 106px;
        height: 60px;
        line-height: 60px;
        float: left;
        padding-top: 11px;
        box-sizing: border-box;
        img{
            width: 106px;
        }
    }

    .nav {
        width: 1200px;
        margin: 0 auto;
        .nav-ul {
            float: left;
            margin-left: 320px;
            .nav-li {
                width: 70px;
                margin: 0 30px;
                text-align: center;
                a {
                    color: #fff;
                    text-decoration: none;
                }
            }
            .el-menu--horizontal{
                .el-menu-item{
                    height: 60px;
                    line-height: 60px;
                    font-size: 18px;
                    padding: 0;
                }
                // .el-menu-item:hover{
                //     background: #185BCE !important;
                // }
                /deep/.el-submenu__title{
                    height: 50px;
                    line-height: 56px;
                    font-size: 16px;
                    padding: 0;
                  
                }
            }
            .el-menu.el-menu--horizontal{
                border-bottom: none;
               
            }
            
        }

    }

    .menu{
        float: left;
        font-size: 18px;
        .about{
            cursor: pointer;
            margin-left: 30px;
            margin-right: 60px;
        }
        .login{
            margin-right: 16px;
            cursor: pointer;
        }
        img{
            margin-top: 15px;
            margin-right: 16px;
        }
    }
}
.el-menu--popup{
    .el-menu-item:hover{
        background: #185BCE !important;
    }
}
.el-menu-item:hover{
    background: #fff!important;
}
.el-submenu__title:hover {
    background: #fff!important;
}

</style>