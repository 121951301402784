<template>
    <el-container>
        <navbar></navbar>
        <el-main>
            <router-view></router-view>
        </el-main>
    </el-container>
</template>
<script>
import navbar from '@/page/website/component/navbar.vue';
export default {
    name: "home",
    data() {
        return {}
    },
    components: {
        navbar,
    },
   
    created() {
        this.$router.push({
            path: '/homePage'
        }).catch(err => {
            err
        })
        
    },
    mounted() {
    },
    methods: {
        
    }
}
</script>

<style scoped lang="less">
.el-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #FBFCFF;
}
.el-main {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    height: calc(100% - 56px - 44px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.footer {
    height: 44px;
    font-size: 14px;
    color: #C8C9CC;
    line-height: 44px;
    text-align: center;
}

</style>