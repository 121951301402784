<template>
    <!--    顶部导航栏-->
    <el-header height="56px">
        <div class="logo">
            <img src="../../assets/img/login_logo.png">
        </div>
        <div class="nav">
            <ul class="nav-ul">
                <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="handleSelect"
                    background-color="#000C17"
                    text-color="#fff"
                    active-text-color="#ffffff">
                    <el-menu-item class="nav-li" index="1" v-if="utils.isDisplay('projectManage:view')">项目管理</el-menu-item>
                    <el-menu-item class="nav-li" index="2" v-if="utils.isDisplay('report:view')">测算报告</el-menu-item>
                    <el-submenu class="nav-li" index="3" v-if="utils.isDisplay('risk:view')">
                        <template slot="title">公开数据</template>
                        <el-menu-item index="3-1" v-if="utils.isDisplay('law:view')">政策法规</el-menu-item>
                        <el-menu-item index="3-2" v-if="utils.isDisplay('policy:view')">政策口径</el-menu-item>
                        <el-menu-item index="3-3" v-if="utils.isDisplay('projectPublic:view')">网签数据</el-menu-item>
                        <el-menu-item index="3-4" v-if="utils.isDisplay('internetProject:view')">互联网数据</el-menu-item>
                        <el-menu-item index="3-5" v-if="utils.isDisplay('landMarket:view')">土地交易</el-menu-item>
                    </el-submenu>
                </el-menu>
                <!-- <li class="nav-li">
                    <router-link to="/projectManagement">项目管理</router-link>
                </li>
                <li class="nav-li">
                    <router-link to="/industryAnalysis">测算报告</router-link>
                </li>
                <li class="nav-li">
                    <router-link to="/home/projectManagement">公开数据</router-link>
                </li> -->
            </ul>
        </div>
         <!-- @click="toPersonalDetails" -->
        <div class="right-menu">
            <div class="user-information">
                <div class="user_box">
                    <i-me theme="outline" size="24" fill="#fff" class="user-img"/>
                    <span class="user-name" v-html="username" @click="toPersonalDetails"></span>
                </div>
                
                <!--                <img src="" alt="用户logo" class="user-img">-->
                <!--                <span class="">vip</span>-->
                <span class="exit-btn" @click="quit()">退出</span>
            </div>
            <!--            下拉功能集合配合下面css样式使用-->
            <!--            <ul class="user-functionality">-->
            <!--                <li>退出登录</li>-->
            <!--            </ul>-->
        </div>
    </el-header>
</template>

<script>
import {clearStore, getStore,setStore, getLocalStore} from "@/util/store";
import {mapMutations} from "vuex";
let pathLength;
export default {
    name: "navbar",
    data() {
        return {
            username: null,
            activeIndex: '1'
        }
    },
    watch:{
        "$route.path":function(){
            if(this.$route.path=='/osdetail'||this.$route.path=='/overview'||this.$route.path=='/onlineSignature'){
                this.activeIndex = '3-3'
            }else if(this.$route.path=='/netWork'||this.$route.path=='/netDetail'||this.$route.path=='/contrast'){
                this.activeIndex = '3-4'
            }else if(this.$route.path=='/landData'){
                this.activeIndex = '3-5'
            }else if(this.$route.path=='/industryAnalysis'||this.$route.path=='/report'||this.$route.path=='/calculate'||this.$route.path=='/setData'){
                this.activeIndex = '2'
            }else{
                this.activeIndex = '1'
            }
            if(this.$route.path=='/personalDetails'){
                if(getStore('pdfrom')=='/industryAnalysis'){
                    this.activeIndex = '2'
                }else if(getStore('pdfrom')=='/onlineSignature' || getStore('pdfrom')=='/overview' || getStore('pdfrom')=='/osdetail' || getStore('pdfrom')=='/policiesRegulation' || getStore('pdfrom')=='/policyCaliber' || getStore('pdfrom')=='/netWork' || getStore('pdfrom')=='/netDetail' || getStore('pdfrom')=='/contrast'){
                    this.activeIndex = '3-1'
                }
            }
        }
    },
    mounted() {
        this.username = getLocalStore('userinfo').username
    },
    methods: {
        ...mapMutations(["reducePath", "addPath", "addMenuList", "reduceMenuList"]),
        quit() {
            this.getReport(2,'nav','SignOut');
            this.$router.push({path: '/login'})
            setTimeout(() => {
                this.$store.commit('reload')
                clearStore()
            }, 3000);
            
        },
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                data: {
                    pageName:'navBar',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        handleSelect(key, keyPath) {
            if(key==1){
                pathLength = getStore('menuLsit').length;
                if(pathLength>1){
                    for(var i=0;i<pathLength-1;i++){
                        this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
                        if(getStore("path")){
                            if(getStore("path").length==1){
                                if(getStore("path")[0]!='项目管理'){
                                    this.reducePath();//删除导航最后一个路由
                                }
                            }else{
                                this.reducePath();//删除导航最后一个路由
                            }
                        
                            if(getStore("path").length==2){
                                this.reducePath();//删除导航最后一个路由
                            }
                        }
                        
                    }
                }
                this.getReport(2,'nav','projectSearch');
                this.$router.push({path: "/projectSearch"});
            }else if(key==2){
                this.getReport(2,'nav','calculationReport');
                this.addMenuList("/industryAnalysis");
                setStore("currentMenu", "/industryAnalysis");
                this.$router.push({path: "/industryAnalysis"});
            }else if(key=='3-1'){
                this.getReport(2,'nav','policyRegulations');
                this.$router.push({path: "/policiesRegulation"});
            }else if(key=='3-2'){
                this.getReport(2,'nav','policyCaliber');
                this.$router.push({path: "/policyCaliber"});
            }else if(key=='3-3'){
                this.getReport(2,'nav','onlineSignature');
                this.$router.push({path: "/onlineSignature"});
            }else if(key=='3-4'){
                this.addMenuList("/netWork");
                setStore("currentMenu", "/netWork");
                this.getReport(2,'nav','netWork');
                this.$router.push({path: "/netWork"});
            }else if(key=='3-5'){
                this.addMenuList("/landData");
                setStore("currentMenu", "/landData");
                this.getReport(2,'nav','landData');
                this.$router.push({path: "/landData"});
            }
        },
        toPersonalDetails(){
            setStore('pdfrom',this.$route.path);
            this.$router.push({path: '/personalDetails'});

        }
    }
}
</script>

<style scoped lang="less">
.el-header {
    width: 100%;
    padding: 0;
    display: flex;
    background: #000C17;
    line-height: 56px;

    .logo {
        width: 223px;
        height: 56px;
        line-height: 56px;
        flex-shrink: 0;
        padding-top: 8px;
        box-sizing: border-box;
        img{
            width: 146px;
        }
    }

    .nav {
        flex-grow: 1;

        .nav-ul {
            display: flex;
            justify-content: center;
            align-items: center;

            .nav-li {
                width: 70px;
                margin: 0 35px;
                text-align: center;
                a {
                    color: #fff;
                    text-decoration: none;
                }
            }
            .el-menu--horizontal{
                .el-menu-item{
                    height: 50px;
                    line-height: 56px;
                    font-size: 16px;
                    padding: 0;
                }
                // .el-menu-item:hover{
                //     background: #185BCE !important;
                // }
                /deep/.el-submenu__title{
                    height: 50px;
                    line-height: 56px;
                    font-size: 16px;
                    padding: 0;
                  
                }
            }
            .el-menu.el-menu--horizontal{
                border-bottom: none;
               
            }
            
        }

    }

    .right-menu {
        padding-right: 20px;
        box-sizing: border-box;
        flex-shrink: 0;
        min-width: 223px;
        position: relative;
        /*background-color: #e0e0e0;*/

        /*如果以后要用户名下面出现多功能可以放出来直接用*/
        /*&:hover .user-functionality{*/
        /*    display:block;*/
        /*}*/
        /*.user-functionality{*/
        /*    display:none;*/
        /*    right:30px;*/
        /*    top:56px;*/
        /*    position:absolute;*/
        /*    width:80%;*/
        /*    background-color: #fff;*/
        /*    box-shadow: 5px 5px 5px #e0e0e0;*/
        /*    border:1px solid #e0e0e0;*/
        /*    border-radius: 4px;*/
        /*    li{*/
        /*        height:30px;*/
        /*        line-height:30px;*/
        /*        margin:5px 8px;*/
        /*        cursor: pointer;*/
        /*        border-radius: 4px;*/
        /*        &:hover{*/
        /*            background-color: #0499FF;*/
        /*            color:#fff;*/
        /*        }*/
        /*    }*/
        /*}*/

        .user-information {
            width: 100%;
            height: 100%;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: flex-end;
            .user_box{
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: flex-end; 
            }
            .user-img {
                line-height: 1;
                width: 24px;
                height: 24px;
                font-size: 12px;
                margin-right: 8px;

            }

            .user-name {
                font-size: 14px;
                color: #FFFFFF;
            }

            .exit-btn {
                margin-left: 18px;
                color: rgba(255, 255, 255, 0.6);
                font-weight: 400;

                &:hover {
                    color: rgba(255, 255, 255, 1);
                }
            }

        }
    }
}
.el-menu--popup{
    .el-menu-item:hover{
        background: #185BCE !important;
    }
}
</style>