<template>
    <div class="login-body">
        <div class="main" id="main">
            <div class="blue_bg"></div>
            <div class="white_bg"></div>
            <div class="login_bg"></div>
        </div>
        <div class="container">
            <img src="../../assets/img/pd_logo.png" class="logo" alt="">
            <div style="width:100%;height:100%" v-if="isRegister">
                <div class="one" v-if="isFirst">
                    <div class="step_box">
                        <div class="step_item one active">
                            <div class="step_num">1</div>
                            <div class="step_name">账户信息</div>
                        </div>
                        <div class="line first"></div>
                        <div class="step_item two">
                            <div class="step_num">2</div>
                            <div class="step_name">详细信息</div>
                        </div>
                        <div class="line second"></div>
                        <div class="step_item three">
                            <div class="step_num">3</div>
                            <div class="step_name">注册成功</div>
                        </div>
                    </div>
                    <div class="register_input">
                        <div class="reg_left">
                            <div class="reg_name">用户名</div>
                            <div class="reg_name">设置密码</div>
                            <div class="reg_name">确认密码</div>
                        </div>
                        <div class="reg_right">
                            <el-form ref="" class="login">
                                <el-form-item>
                                    <el-input v-model="reg_username"  autocomplete="off"
                                            placeholder="请输入您的用户名，用户名将无法修改"></el-input>
                                    <span class="ps">6-30个字符 可以包含字母、数字和点(.)</span>
                                    <span class="tips" v-if="userError">{{userText}}</span>
                                </el-form-item>
                                <el-form-item>
                                    <el-input  show-password  v-model="reg_pwd"  auto-complete="new-password"
                                            placeholder="请输入您的密码"></el-input>
                                    <span class="ps">6-30个字符 需同时包含字母大小写、数字 </span>
                                    <span class="tips" v-if="pwdError">{{pwdText}}</span>

                                </el-form-item>
                                <el-form-item>
                                    <el-input class="userpwd-input" show-password v-model="reg_pwd_ag" auto-complete="new-password"
                                            placeholder="请再输入一遍您的密码"></el-input>
                                    <span class="tips" v-if="pwdAgainError">与新密码不一致</span>
                                    
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <el-button class="next_btn" type="primary" @click="next(1)">下一步</el-button>
                </div>
                <div class="one" v-if="isSecond">
                    <div class="step_box">
                        <div class="step_item one">
                            <div class="step_num">1</div>
                            <div class="step_name">账户信息</div>
                        </div>
                        <div class="line first"></div>
                        <div class="step_item two active">
                            <div class="step_num">2</div>
                            <div class="step_name">详细信息</div>
                        </div>
                        <div class="line second"></div>
                        <div class="step_item three">
                            <div class="step_num">3</div>
                            <div class="step_name">注册成功</div>
                        </div>
                    </div>
                    <div class="register_input">
                        <div class="reg_left">
                            <div class="reg_name">区域</div>
                            <div class="reg_name">所属行业</div>
                            <div class="reg_name">职能</div>
                        </div>
                        <div class="reg_right">
                            <el-form ref="" class="login">
                                <el-form-item>
                                    <el-cascader
                                            size="large"
                                            :options="areaData"
                                            v-model="selectedOptions"
                                            @change="areaHandleChange">
                                    </el-cascader>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="dictIndustry" placeholder="请选择">
                                        <el-option
                                            v-for="item in dictIndustryData"
                                            :key="item.industryCode"
                                            :label="item.industryName"
                                            :value="item.industryCode"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-input class="userpwd-input" v-model="reg_work" auto-complete="new-password"
                                            placeholder="请输入您的职能"></el-input>
                                    
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <el-button class="next_btn" type="primary" @click="next(2)">下一步</el-button>
                </div>
                <div class="one" v-if="isThird">
                    <div class="step_box">
                        <div class="step_item one">
                            <div class="step_num">1</div>
                            <div class="step_name">账户信息</div>
                        </div>
                        <div class="line first"></div>
                        <div class="step_item two">
                            <div class="step_num">2</div>
                            <div class="step_name">详细信息</div>
                        </div>
                        <div class="line second"></div>
                        <div class="step_item three active">
                            <div class="step_num">3</div>
                            <div class="step_name">注册成功</div>
                        </div>
                    </div>
                    <div class="success_icon el-icon-success"></div>
                    <p class="reg_success">您已成功注册云策</p>

                   
                    <div class="goIndex" type="primary" @click="next(3)">立即体验云策</div>
                </div>
            </div>
            <div v-if="!isRegister">
                <div class="con_left"></div>
                <div class="landing-box">
                    <img src="../../assets/img/welcome.png" class="welcome" alt="">
                    <p class="login_title">欢迎使用<span>云策</span></p>
                    <div v-if="isLogin">
                        <div class="login_type">
                            <div class="code_login" :class="{'active':activeName == 'yzm'}" @click="loginType('yzm')">验证码登录</div>
                            <div class="password_login" :class="{'active':activeName == 'pwd'}"  @click="loginType('pwd')">密码登录</div>
                        </div>
                        <div v-show="activeName == 'pwd'">
                            <el-form ref="landing" :model="form" class="login">
                                <el-form-item>
                                    <el-input class="username-input" v-model="form.username"
                                            placeholder="请在此输入账号"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-input class="userpwd-input" v-model="form.password" show-password 
                                            placeholder="请在此输入密码"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-input class="username-input code-input" v-model="verificationCode" placeholder="请输入验证码" @keyup.enter.native="loginFunc"></el-input>
                                    <div class="code" @click="getCaptcha"><img v-bind:src="src"/></div>
                                </el-form-item>
                                <div class="pwd_forget" @click="forgetPwd">忘记密码？</div>
                                <div style="text-align: center">
                                    <el-button class="login-button" type="primary" @click="loginFunc"
                                            @keyup.enter.native="loginFunc">登录
                                    </el-button>
                                </div>
                                <div class="check_box">
                                    <el-checkbox v-model="checked">我已同意</el-checkbox>
                                    《<a href="https://data.puduoinfo.com/#/agreement" target="_blank">服务协议</a>》
                                </div>
                            </el-form>
                        </div>
                        <div v-show="activeName == 'yzm'">
                            <el-form ref="landing" :model="phoneForm" class="login reg">
                                <el-form-item>
                                    <el-input class="username-input" v-model="phoneNumber"  @input="changeCode"
                                            placeholder="请输入手机号" @change="checkPhoneNum"></el-input>
                                    <span class="reg_ps" v-if="isPhoneNum">手机号码格式不正确</span>
                                </el-form-item>
                                <el-form-item>
                                    <el-input class="username-input code-input" style="width:155px" @input="changeCode" @change="checkPhoneCode" v-model="phoneCode" placeholder="请输入短信验证码"></el-input>
                                    <el-button class="getcode" :class="disabled?'disable':''" :disabled="disabled" @click="getVerificationCode('')">{{text}}</el-button>
                                    <span class="reg_ps" v-if="isPhoneCode">验证码不正确</span>
                                </el-form-item>
                                <div class="reg_btn" style="text-align: center">
                                    <el-button class="login-button" type="primary" @click="smsLogin" :disabled="isClick=='false'? true:false"
                                            @keyup.enter.native="smsLogin">注册/登录
                                    </el-button>
                                </div>
                                <div class="check_box">
                                    <el-checkbox v-model="checked">我已同意</el-checkbox>
                                    《<a href="https://data.puduoinfo.com/#/agreement" target="_blank">服务协议</a>》
                                </div>
                            </el-form>
                        </div>
                    </div>
                    <!-- 重置密码 -->
                    <div class="reset_box" v-if="!isLogin">
                        <p class="resetpwd">重置密码</p>
                        <div>
                            <el-form ref="landing" :model="resetFrom" class="login">
                                <el-form-item>
                                    <el-input class="username-input phone" v-model="phoneNumber"
                                           placeholder="请输入手机号"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-input type="text" class="userpwd-input" v-model="resetPwd"
                                            @focus="changeType" placeholder="请输入新密码"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-input class="username-input code-input" v-model="resetCode" placeholder="请输入短信验证码"></el-input>
                                    <el-button class="getcode" :class="disabled?'disable':''" :disabled="disabled" @click="getVerificationCode('update')">{{text}}</el-button>
                                </el-form-item>
                                <div>
                                    <el-button class="cancel_reset" type="primary" @click="cancel">取消修改
                                    </el-button>
                                    <el-button class="reset-button" type="primary" @click="resetPassWord">确认修改
                                    </el-button>
                                </div>
                            </el-form>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
            
        </div>
        <p class="footer">Copyright© 2021-2022 浦舵信息科技（上海）有限公司</p>
        <!-- <input type="password" style="display:none"> -->
    </div>
</template>

<script>
import {setLocalStore,getStore,setStore,getLocalStore} from "@/util/store";
import {Loading} from "element-ui";
import {regionData, CodeToText, TextToCode} from 'element-china-area-data';// 地址级联选择器
// import lottie from 'lottie-web';
// import * as animationData from "@/util/lottie.json";
let Base64 = require('js-base64').Base64;
export default {
    name: "login",
    data() {
        return {
            phoneForm:{},
            form: {
                username: '',
                password: '',
            },
            resetFrom:{},
            verificationCode: '',
            phoneCode:'',
            resetCode:'',
            src: '',
            checked: true,
            activeName: 'pwd',
            phoneNumber:'',
            isLogin: true,
            isRegister: false,
            isFirst: false,
            isSecond: false,
            isThird: false,
            reg_username:'',
            reg_pwd:'',
            reg_pwd_ag:'',
            areaData:[],
            selectedOptions: [],
            reg_work:'',
            dictIndustry:'',
            dictIndustryData:[],
            resetPwd:'',
            codeAnswer:'',
            phoneCodeAnsw:'',
            time:0,
            disabled:false,
            codeUse:'',
            userError:false,
            pwdError:false,
            pwdAgainError:false,
            pwdText:'密码长度不能小于6个字符',
            userText:'用户名已被占用',
            regionCode:'',
            regData:{},
            isClick: 'false',//登录注册按钮是否可点击
            isPhoneCode: false,
            isPhoneNum: false,
        }
    },
    created(){
        this.areaData = regionData;
        this.getCaptcha();
    },
    destroyed(){
    },
    mounted: function () {

        //测试lottie动画
        // console.log(lottie);
        // animationData.assets.forEach(item => {
        //     item.u = "";
        //     if (item.w && item.h) {
        //     item.p = require(`@/assets/lottie/images/${item.p}`);
        //     }
        // }); 
        // this.lottie = lottie.loadAnimation({
        //     container: document.getElementById('main'),
        //     renderer: 'svg',
        //     animationData: animationData.default,
        //     // path: '/src/util/lottie.json'
        //     // assetsPath: '/src/util/lottie.json'
        // })
    },
    computed: {
        text: function () {
            return this.time > 0 ? this.time + 's 后重发送' : '获取验证码';
        }
    },

    methods: {
        loginType(type){
            this.activeName = type;
        },
        forgetPwd(){
            this.isLogin = false;
        },
        //密码框
        changeType(e){
            e.srcElement.type = 'password'
        },
        //取消修改
        cancel(){
            this.isLogin = true;
        },
        areaHandleChange (value) {
            this.regionCode = value[2]
        },
        //获取行业数据
        getDictIndustry(){
            this.axios.request({
                method: "get", 
                url: '/dms/getDictIndustry',
            }).then((res) => {
               this.dictIndustryData = res.data.data;
            })
        },
        //获取图形验证码
        getCaptcha(){
            this.axios.request({
                method: "get", 
                url: '/dms/getCaptcha',
            }).then((res) => {
                this.src = res.data.data.image;
                this.codeAnswer = res.data.data.code;
               
            })
        },
        //获取短信验证码
        getVerificationCode(codeUse){
            var myreg=/^[1][3,4,5,7,8,9][0-9]{9}$/;
            if(this.phoneNumber==""){
                this.$message.error('请输入手机号');
                return false;
            }else if(!myreg.test(this.phoneNumber)){
                this.$message.error('手机号格式有误');
                return false;
            }else{
                this.axios.request({
                    method: "get", 
                    url: '/dms/getVerificationCode',
                    params:{
                        phoneNumber: this.phoneNumber,
                        codeUse:codeUse
                    }
                }).then((res) => {
                    if(res.data.status=='success'){
                        this.phoneCodeAnsw = Base64.decode(res.data.data.code);
                        this.codeUse = res.data.data.use;
                        // this.codeUse = 'register';
                        this.disabled = true;
                        this.time = 60;
                        this.timer();
                    }else{
                        if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                        }else{
                            this.$message.error(res.data.msg);
                        }
                    }
                
                })
            }
            
        },
        timer: function () {
            if (this.time > 0) {
                this.time--;
                setTimeout(this.timer, 1000);
            }else{
            	this.disabled = false;
            }
        },
        //校验手机号
        checkPhoneNum(){
            let reg = /^1((3[0-9])|(4[1579])|(5[0-9])|(6[6])|(7[0-9])|(8[0-9])|(9[0-9]))\d{8}$/
            if(reg.test(this.phoneNumber)){
                this.isPhoneNum = false
            }else{
                this.isPhoneNum = true
            }
        },
        //校验验证码
        checkPhoneCode(){
            if (this.phoneCode.length!=4) {
                this.isPhoneCode = true
            }else{
                this.isPhoneCode = false
            }
        },
        changeCode(){
            if(this.phoneNumber.length>0&&this.phoneCode.length>0){
                this.isClick = 'true'
            }else{
                this.isClick = 'false'
            }
        },
        //注册/手机验证码登录
        smsLogin(){
            if (!this.checked) {
                this.$message.error('是否同意服务协议');
                return false;
            }else if (this.isPhoneCode||this.isPhoneNum) {
                return false;
            }else if (this.phoneCodeAnsw!=this.phoneCode) {
                this.isPhoneCode = true
                return false;
            }else{
                if(this.codeUse=="register"){
                    this.isRegister = true;
                    this.isFirst = true;
                }else{
                    this.axios.request({
                        method: "post", 
                        url: '/dms/smsLogin',
                        data:{
                            phoneNumber: this.phoneNumber,
                            token:''
                        }
                    }).then((res) => {
                        if (res.data.status == "failed") {
                            if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                        
                            }else{
                                this.$message.error(res.data.msg);
                            }
                        } else if (res.data.status == "success") {
                            setLocalStore('loginName', res.data.data.user.loginName)
                            setLocalStore('userinfo', res.data.data.user)
                            setLocalStore('token', res.data.data.token)
                            setLocalStore('permission', res.data.data.permission)
                            this.getArea();
                        }
                    })
                }
                
            }
            
        },
        //注册下一步
        next(step){
            if(step==1){
                var reg = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d).*$/;
                if(this.reg_username.length<6||this.reg_username.length>30){
                    this.userText='用户名长度在6-30位之间'
                    this.userError = true;
                    return false;
                }else if(this.reg_pwd.length<6 || this.reg_pwd.length>30){
                    this.pwdText='密码长度在6-30位之间'
                    this.pwdError = true;
                    this.userError = false;
                    return false;
                }else if(!reg.test(this.reg_pwd)){
                    this.pwdText='用户名密码必须同时包含字母大小写、数字'
                    this.pwdError = true;
                    this.userError = false;
                    return false;
                }else if(this.reg_pwd!=this.reg_pwd_ag){
                    this.pwdAgainError = true;
                    this.userError = false;
                    return false;
                }else{
                    this.axios.request({
                        method: "post", 
                        url: '/dms/saveRegisterLoginInfo',
                        data: {
                            id: '',
                            phoneNumber: this.phoneNumber,
                            loginName: this.reg_username,
                            userName: "",
                            loginPassword: this.reg_pwd,
                            regionCode: "",
                            industryCode: "",
                            jobName: "",
                            message: ""
                        },
                    }).then((res) => {
                        if(res.data.status=="success"){
                            this.regData = res.data.data
                            //获取行业数据
                            this.getDictIndustry();

                            this.isFirst = false;
                            this.isSecond = true;
                            this.isThird = false;
                        }else{
                            if(res.data.msg=="用户名已被占用"){
                                this.userError = true;
                            }else{
                                this.$message.error(res.data.msg);
                            }
                            
                            return false;
                        }
                    })
                }
                
            }else if(step==2){
                if(this.regionCode==''){
                    this.$message.error('请选择省市区');
                    return false;
                }else if(this.dictIndustry==''){
                    this.$message.error('请选择所属行业');
                    return false;
                }else if(this.reg_work==''){
                    this.$message.error('请输入职能');
                    return false;
                }else if(this.reg_work.length>30){
                    this.$message.error('职能请控制在30字以内');
                    return false;
                }else{
                    this.regData.jobName = this.reg_work;
                    this.regData.regionCode = this.regionCode;
                    this.regData.industryCode = this.dictIndustry;
                    this.axios.request({
                        method: "post", 
                        url: '/dms/saveRegisterDetailedInfo',
                        data: this.regData,
                    }).then((res) => {
                        if(res.data.status=="success"){
                            this.isFirst = false;
                            this.isSecond = false;
                            this.isThird = true;
                            setLocalStore('loginName', res.data.data.user.loginName)
                            setLocalStore('userinfo', res.data.data.user)
                            setLocalStore('token', res.data.data.token)
                            setLocalStore('permission', res.data.data.permission)
                        }else{
                            this.$message.error(res.data.msg);
                            return false;
                        }
                    })
                }
                
            }else{
                this.getArea();
            }
        },
        //重置密码
        resetPassWord(){
            var phone_reg = /^1((3[0-9])|(4[1579])|(5[0-9])|(6[6])|(7[0-9])|(8[0-9])|(9[0-9]))\d{8}$/
            var reg = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d).*$/;
            if(!phone_reg.test(this.phoneNumber)){
                this.$message.error('手机号格式输入有误');
                return false;
            }else if(this.resetPwd.length<6 || this.resetPwd.length>30){
                this.$message.error('密码长度在6-30位之间');
                return false;
            }else if(!reg.test(this.resetPwd)){
                this.$message.error('用户名密码必须同时包含字母大小写、数字');
                return false;
            }else if (this.resetCode.length==0) {
                this.$message.error('请输入手机验证码');
                return false;
            }else if (this.phoneCodeAnsw!=this.resetCode) {
                this.$message.error('手机验证码输入有误');
                return false;
            }else{
                this.axios.request({
                    method: "post", 
                    url: '/dms/forgetPwd',
                    data:{
                        phoneNumber: this.phoneNumber,
                        password:this.resetPwd,
                    }
                }).then((res) => {
                    if(res.data.status=='success'){
                        this.$message.success(res.data.msg);
                        this.isLogin = true;
                    }
                })
            }
            
        },
        //获取ip和地址信息
        getArea(){
            this.axios.request({
                method: "get", 
                url: '/dms/getUserBehaviorParam',
                params: {
                    userId:getLocalStore('userinfo').id,
                    userName:getLocalStore('userinfo').username
                },
            }).then((res) => {
               setLocalStore('areainfo', res.data.data);
                //展现
                this.getReport(0,'','')
                //销毁
                this.getReport(1,'','')
                //点击
                this.getReport(2,'login','loginBtn')

                this.$message.success('登录成功！')
                this.$router.push('/home')
            })
        },
        //临时登录界面
        loginFunc() {
            if (this.form.username === '') {
                this.$message.error('请输入账号');
                return false;
            } else if (this.form.password === '') {
                this.$message.error('请输入密码');
                return false;
            } else if (!this.checked) {
                this.$message.error('是否同意服务协议');
                return false;
            } else if (this.verificationCode.toLowerCase() != this.codeAnswer.toLowerCase()) {
                this.$message.error('验证码输入不正确');
                return false;
            } else {
                let loading_login = Loading.service({background: 'rgba(0, 0, 0, 0.5)'});
                this.axios.request({
                    loading: true,
                    method: "post", url: '/dms/loginByAccount',
                    data: this.form,
                }).then((res) => {
                    loading_login.close();
                    if (res.data.status == "failed") {
                        if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                        }else{
                            this.$message.error(res.data.msg);
                        }
                    } else if (res.data.status == "success") {
                        setLocalStore('loginName', res.data.data.user.loginName)
                        setLocalStore('userinfo', res.data.data.user)
                        setLocalStore('token', res.data.data.token)
                        setLocalStore('permission', res.data.data.permission)
                        this.getArea();
                    }
                })
            }
        },
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                headers:{
                    'Authorization':'Bearer '+getLocalStore('token')
                },
                data: {
                    pageName:'login',
                    userName:'',
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:'',
                    ipAddress:'',
                    userAddress:'',
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        toAgreement(){
            this.$router.push('/agreement')
        }
    }
}
</script>

<style scoped lang="less">
input:-webkit-autofill{
	-webkit-box-shadow:0 0 0 1000px white inset !important;
}
.login-body {
    position: relative;
    width: 100%;
    height: 100%;
    .footer{
        position: fixed;
        width: 100%;
        bottom: 24px;
        text-align: center;
        color: #C8C9CC;
        font-size: 14px;
    }

    .main {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 1;
        top: 0;
        left: 0;
        min-width: 52.083vw /* 1000/19.2 */;
        .blue_bg{
            width: 38%;
            height: 100%;
            float: left;
            background: #185BCE;
        }
        .white_bg{
            width: 62%;
            height: 100%;
            float: left;
            background: #F3F8FD
        }
        .login_bg{
            width: 70%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(../../assets/img/login_bg.png) no-repeat;
        }
    }

    .main-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: "";
        opacity: 0.5;
        background-color: #000000;
    }

    .logo {
        position: absolute;
        top: 16px;
        left: 16px;
    }

    .container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 1100px /* 750/19.2 */;
        height: 620px /* 416/10.8 */;
        background: #FFFFFF;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
        .con_left{
            width: 550px;
            height: 620px;
            float: left;
            background: url('../../assets/img/login_img.png') no-repeat;
        }
        .landing-box {
            width: 550px;
            height: 620px;
            background-color: #FFFFFF;
            box-sizing: border-box;
            float: left;
            z-index: 1.5;
            padding-top: 221px;
            position: relative;
            .welcome{
                position: absolute;
                top: 63px;
                left: 25px;
            }
            .login_title{
                font-size: 26px;
                font-weight: 400;
                color: #333333;
                position: absolute;
                top: 67px;
                left: 119px;
                span{
                    color: #185BCE;
                }
            }
            .login_type{
                width: 292px;
                height: 28px;
                position: absolute;
                top: 167px;
                left: 129px;
                div{
                    height: 28px;
                    cursor: pointer;
                }
                .code_login{
                    float: left;
                    margin-left: 43px;
                }
                .password_login{
                    float: right;
                    margin-right: 43px;
                }
                .active{
                    color: #185BCE;
                    border-bottom: 2px solid #185BCE;
                }
            }
            .resetpwd{
                position: absolute;
                left: 236px;
                top: 161px;
                font-size: 20px;
                color: #185BCE;
            }
            .reg{
                .reg_ps{
                    position: absolute;
                    bottom: -32px;
                    left: 132px;
                    color: #FF3D23;
                }
                /deep/ .el-form-item {
                    margin-bottom: 0px !important;
                    height: 64px;
                    position: relative;
                }
            }
            .login {
                box-sizing: border-box;
                width: 550px;   
                height: 416px;

                /deep/ .el-form-item {
                    margin-bottom: 24px /* 22/10.8 */;
                }

                /deep/ .el-form-item__content {
                    line-height: 40px /* 40/10.8 */;
                    font-size: 14px /* 14/19.2 */;
                }

                .login-title {
                    width: 108px;
                    height: 26px;
                    margin: 0 auto 34px;
                    line-height: 26px;
                    font-size: 18px;
                    color: #323233;
                    font-weight: 400;
                }

                .username-input, .userpwd-input {
                    margin: 0 129px;
                    width: 292px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 8px;
                    font-size: 14px;

                    

                    /deep/ .el-input__icon {
                        width: 18px /* 25/19.2 */;
                        line-height: 40px /* 40/10.8 */;
                    }

                    /deep/ .el-input__prefix {
                        left: 12px /* 5/19.2 */
                    }
                }
                .code-input{
                    width: 164px;
                    float: left;
                    margin-right: 0;
                }
                .code{
                    width: 104px;
                    height: 40px;
                    float: left;
                    margin-left: 24px;
                }
                .getcode{
                    width: 112px;
                    height: 40px;
                    float: left;
                    margin-left: 24px;
                    background: #2967D1;
                    border-radius: 8px;
                    text-align: center;
                    color: #FFFFFF;
                    border: 1px solid #2967D1;
                }
                .disable{
                    background: #F3F8FD;
                    border: 1px solid #F3F8FD;
                    color: #999999;
                }

                .pwd_forget {
                    position: absolute;
                    right: 129px;
                    top: 400px;
                    height: 2.852vh /* 20/10.8 */; //原（1.852）+1
                    line-height: 2.852vh /* 20/10.8 */; //原（1.852）+1
                    font-size: 0.729vw /* 14/19.2 */;
                    font-weight: 500;
                    color: #185BCE;
                    cursor: pointer;
                    z-index: 3;
                }
                .cancel_reset{
                    width: 134px;
                    margin-left: 129px;
                    margin-right: 24px;
                    background-color: #b2b2b2 !important;
                    border-color:#b2b2b2 !important;
                }
                .reset-button{
                    width: 134px;
                    margin-left: 0;
                }
                .reg_btn{
                    .is-disabled{
                        background: rgba(24, 91, 206, 0.5) !important;
                        border: rgba(24, 91, 206, 0.5) !important;
                    }
                }
                .login-button {
                    position: relative;
                    margin: 18px auto;
                    width: 292px;
                    height: 40px;
                    line-height: 16px;
                    text-align: center;
                    background: #185BCE;
                    border-radius: 8px;
                    font-size: 14px;

                    /deep/ .el-form-item__content {
                        //line-height: 3.704vh /* 40/10.8 */;
                        font-size: 14px;
                    }
                }


            }
            .check_box{
                padding-left: 129px;
                a{
                    text-decoration: none;
                    color: #185BCE;
                }
                /deep/.el-checkbox__inner{
                    // background: #185BCE !important;
                    border-color: #185BCE !important;
                    color: #185BCE !important;
                }
                .is-checked /deep/.el-checkbox__inner{
                    background: #185BCE !important;
                }
                /deep/.el-checkbox__label{
                    color: #333333 !important;
                }
            }

            //.code_input {
            //    width: 100%;
            //
            //    .el-input {
            //        float: left;
            //        width: calc(100% - 100px);
            //        margin-right: 20px;
            //    }
            //
            //    .code {
            //        float: right;
            //        width: 80px;
            //
            //        img {
            //            width: 80px;
            //            height: 30px;
            //        }
            //    }
            //}
        }
        .one{
            width: 100%;
            height: calc(100% - 98px);
            position: relative;
            box-sizing: border-box;
        }
        .register_input{
            width: 374px;
            position: relative;
            margin: 0 auto;
            margin-top: 78px;
            .reg_left{
                float: left;
                width: 58px;
                text-align: right;
            }
            .reg_name{
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                color: #333333;
                margin-bottom: 24px;
            }
            .reg_right{
                width: 292px;
                float: right;
                .el-form-item{
                    position: relative;
                    .ps{
                        position: absolute;
                        width: 220px;
                        right: -232px;
                        font-size: 12px;
                    }
                    .tips{
                        position: absolute;
                        bottom: -32px;
                        left: 0;
                        font-size: 12px;
                        color: #FF3D23;
                    }
                }
            }
            
        }
        .step_box{
            width: 612px;
            height: 60px;
            margin: 0 auto;
            position: relative;
            margin-top: 98px;
            .step_item{
                position: absolute;
                width: 56px;
                height: 60px;
                top: 0;               
                
                .step_num{
                    width: 24px;
                    height: 24px;
                    border-radius: 24px;
                    background: #C8C9CC;
                    color: #ffffff;
                    line-height: 24px;
                    text-align: center;
                    margin: 0 auto;
                    margin-bottom: 8px;
                }
                .step_icon{
                    width: 100%;
                    height: 24px;
                    margin: 0 auto;
                    text-align: center;
                    margin-bottom: 8px;
                }
                .step_icon::before{
                    color: #185BCE;
                    font-size: 24px;
                    font-weight: 600;
                }
                .step_name{
                    font-size: 14px;
                    font-weight: 500;
                    color: #969799;
                }
                
            }
            .active{
                .step_num{
                    background: #185BCE;
                }
                .step_name{
                    color: #323233;
                    font-weight: 600;
                }
            }
            .line{
                width: 240px;
                height: 2px;
                background: #F2F3F5;
                position: absolute;
                top: 12px; 
            }
            .line.active{
                background: #185BCE;
            }
            .first{
                left: 48px;
            }
            .second{
                left: 325px;
            }
            .one{
                left: 0;
            }
            .two{
                left: 280px;
            }
            .three{
                right: 0;
            }
            
        }
        .next_btn{
            position: absolute;
            bottom: 103px;
            left: 494px;
        }
    }
    /deep/ .el-input__inner {
        background-color: #F3F8FD;
        box-shadow: 0 0 0px 1000px #F3F8FD inset;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 16px;
        border: none !important;
    }
    .el-cascader{
        width: 100%;
    }
    .el-select{
        width: 100%;
    }
    .success_icon{
        color: #2DA641;
        font-size: 61px;
        position: absolute;
        left: 520px;
        bottom: 294px;
    }
    .reg_success{
        text-align: center;
        width: 100%;
        margin-top: 180px;
    }
    .goIndex{
        width: 112px;
        height: 40px;
        line-height: 40px;
        position: absolute;
        left: 494px;
        text-align: center;
        background: #2967D1;
        color: #FFFFFF;
        border-radius: 8px;
        bottom: 88px;
        cursor: pointer;
    }
}
</style>