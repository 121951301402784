<template>
    <el-container>
        <navbar></navbar>
        <el-main>
            <router-view></router-view>
        </el-main>
        <feedback></feedback>
        <div class="footer">
            Copyright© 2021-2022 浦舵信息科技（上海）有限公司
        </div>
    </el-container>
</template>
<script src="../../util/request.js"></script>
<script>
import navbar from '@/components/NavBar/navbar.vue';
import feedback from '@/components/feedback/feedback.vue';
import {getStore,setStore} from "@/util/store";
import {mapState} from "vuex";
export default {
    name: "home",
    data() {
        return {}
    },
    components: {
        navbar,
        feedback
    },
    computed: mapState({
        navLevel: (state) => state.pathLevel //获取导航层级
    }),
    watch: {
        navLevel(){
            if(this.navLevel<=1){
                window.addEventListener('popstate', function () {
                    // console.log('无法再次返回')
                    history.pushState(null, null, document.URL);
                });
            }
        },
    },
    created() {
        // this.getArea();
        // var obj = {
        //     "userId": "10584",
        //     "userName": "李磊",
        //     "ipAddress": "127.0.0.1",
        //     "userAddress": "内网用户"
        // }
        // setLocalStore('areainfo',obj);
       var $url = '/projectManagement';
        if(this.utils.isDisplay('projectManage:view')){
            $url = '/projectManagement';
        }else if(this.utils.isDisplay('report:view')){
            $url = '/industryAnalysis';
        }else if(this.utils.isDisplay('risk:view')){
            $url = '/policiesRegulation';
        }
        this.$router.push({
            path: $url
        }).catch(err => {
            err
        })
        
    },
    mounted() {
    },
    methods: {
        
    }
}
</script>

<style scoped lang="less">
.el-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #F2F3F5;
}
.el-main {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    height: calc(100% - 56px - 44px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.footer {
    height: 44px;
    font-size: 14px;
    color: #C8C9CC;
    line-height: 44px;
    text-align: center;
}

</style>