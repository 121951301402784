<template>
    <div class="feed_main">
        <div class="feed_box" v-show="isFeed">
            <div class="feed_card">
                <div class="feed_header">
                    <div class="feed_title">问题反馈</div>
                    <div class="el-icon-close feed_close" @click="feedClose()"></div>
                </div>
                <div class="feed_form">
                    <div class="feed_left">
                        <div class="feed_question"><i>* </i>功能所属模块：</div>
                        <div class="feed_question"><i>* </i>功能是否有用：</div>
                        <div class="feed_proposal">问题或建议：</div>
                        <div class="feed_question">联系方式：</div>
                    </div>
                    <div class="feed_right">
                        <el-select v-model="module" placeholder="请选择">
                            <el-option
                            v-for="item in options"
                            :key="item"
                            :label="item"
                            :value="item">
                            </el-option>
                        </el-select>
                        <div class="feed_radio">
                            <el-radio v-model="radio" label="1">是</el-radio>
                            <el-radio v-model="radio" label="2">否</el-radio>
                        </div>
                        <el-input v-model="content" type="textarea" :rows="3"  resize="none" maxlength="300" show-word-limit placeholder="输入问题或建议" />
                        <el-input v-model="contact" maxlength="30" placeholder="输入手机号、邮箱"></el-input>
                    </div>
                </div>
                <div class="feed_btn">
                    <div class="feed_cancel" @click="feedClose()">取消</div>
                    <div class="feed_submit" @click="feedSubmit()">提交</div>
                </div>

            </div>
        </div>
        <div class="feedback" @click="toFeedBack()">
            <img src="../../assets/img/feedback.png" alt="">
        </div>
    </div>
</template>

<script>

export default {
    name: "feedback",
    data() {
        return {
            isFeed: false,
             options: ['投资关系分析','合作关系分析','与施工单位关联关系分析','法律风险','舆情风险','历史建设项目','区域分析','周边情况','行业分析','成本分析','数据差异分析','全生命周期管理','总体分析'],
             module: '',
             radio: '',
             content: '',
             contact: ''
        }
    },
    mounted() {
    },
    methods: {
        toFeedBack() {
            this.isFeed = true;
        },
        feedClose() {
            this.isFeed = false;
        },
        checkEmail(str){
            let re = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/; 
            if (re.test(str)) {
                return true
            } else {
                return false
            }
        },
        checkPhone(str){
            let re = /^1[0-9]{10}$/; 
            if (re.test(str)) {
                return true
            } else {
                return false
            }
        },
        feedSubmit() {
            if( this.module.length == 0 ) {
                 this.$message.error('请选择必选项');
                 return;
            }
            if( this.radio.length == 0 ) {
                 this.$message.error('请选择必选项');
                 return;
            }
            if(!this.checkEmail(this.contact) && !this.checkPhone(this.contact)){
                this.$message.error('联系方式输入有误');
                return;
            }
         
            let isUseful;
            if( this.radio == '1' ) {
                isUseful = true
            } else {
                isUseful = false
            }
            this.axios.request({
                loading: true,
                method: "post", url: '/dms/feedback/save',
                data: {
                    isUseful: isUseful,
                    module: this.module,
                    content: this.content,
                    contact: this.contact
                },
            }).then((res) => {
                if(res.data.status=='success'){
                    this.$message.success(res.data.msg);
                    this.isFeed = false;
                    this.module = ''
                    this.content = ''
                    this.contact = ''
                    this.radio = ''
                }else{
                    this.isFeed = false;
                    if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                    }else{
                        this.$message.error(res.data.msg);
                    }
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
.feed_box{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9;
    .feed_card{
        width: 600px;
        height: 400px;
        background: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        .feed_header{
            width: 100%;
            height: 52px;
            border-bottom: 1px solid #DCDEE0;
            .feed_title{
                float: left;
                font-size: 14px;
                font-weight: 500;
                color: #323233;
                line-height: 52px;
                margin-left: 16px;
            }
            .feed_close{
                float: right;
                margin-right: 16px;
                margin-top: 19px;
                cursor: pointer;
            }
        }
        .feed_form{
            width: 350px;
            height: 280px;
            margin: 0 auto;
            overflow: hidden;
            padding-top: 46px;
            box-sizing: border-box;
            .feed_left{
                width: 110px;
                float: left;
                text-align: right;
                .feed_question{
                    height: 32px;
                    line-height: 32px;
                    margin-bottom: 12px;
                    >i{
                        color: #D40000;
                    }
                }
                .feed_proposal{
                    padding-top: 6px;
                    height: 74.4px;
                    box-sizing: border-box;
                    margin-bottom: 12px;
                }
            }
            .feed_right{
                width: 240px;
                float: right;
                .feed_radio{
                    height: 32px;
                    line-height: 32px;
                    margin-bottom: 12px;
                }
            }
        }
        .feed_btn{
            width: 168px;
            height: 32px;
            position: absolute;
            left: 224px;
            bottom: 16px;
            >div{
                float: left;
                width: 76px;
                height: 32px;
                box-sizing: border-box;
                line-height: 30px;
                text-align: center;
                border-radius: 2px;
                cursor: pointer;
            }
            .feed_cancel{
                border: 1px solid #DCDEE0;
                color: #323233;
            }
            .feed_submit{
                margin-left: 16px;
                background: #185BCE;
                border: 1px solid #185BCE;
                color: #ffffff;
            }
        }

    }
}
.feedback{
    width: 38px;
    height: 38px;
    border-radius: 38px;
    text-align: center;
    line-height: 46px;
    position: absolute;
    bottom: 47px;
    right: 41px;
    background: #ffffff;
    cursor: pointer;
}
.el-select{
    height: 32px;
    margin-bottom: 12px;
    /deep/.el-input__icon{
        line-height: 32px !important;
    }
}
/deep/.el-input__inner{
    width: 240px;
    height: 32px !important;
    line-height: 32px !important;
}
/deep/.el-textarea{
    margin-bottom: 12px;
}

</style>